.App {
  text-align: center;
}

body {
  font-family: "Oswald", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Nav.css */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.mobileNavMain {
  display: none;
}

.logo {
  font-size: 24px;
}

.nav-links {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  background-color: #ab9e90;
  font-family: "Roboto";
  text-transform: uppercase;
  font-size: 13px;
  padding: 15px 0px;
}

.imageButton p {
  color: white;
  text-transform: uppercase;
  font-size: 26px;
  margin-bottom: 15px;
}

.imageButton button {
  background: transparent;
  border: solid white;
  padding: 10px 20px;
  color: white;
  font-family: "Roboto";
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
  cursor: pointer;
}

.nav-links a {
  margin: 0 20px;
  color: white;
  text-decoration: none;
}

/* MainHero.css */
.main-hero {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ab9e90;
  font-size: 99px;
  text-transform: uppercase;
  color: white;
  padding-top: 100px;
  height: 87vh;
  text-align: center;
}

/* Services.css */
.services {
  display: flex;
  width: 100%;
  gap: 0;
  justify-content: center;
}

.service1 {
  text-align: center;
  background-image: url("/public/images/IMG_3953-1280w.JPG");
  width: 33.33333%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
}

.service2 {
  text-align: center;
  background-image: url("/public/images/IMG_3872-1280w.JPG");
  width: 33.33333%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
}

.service3 {
  text-align: center;
  background-image: url("/public/images/servicesPrices.png");
  width: 33.33333%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
}

button a {
  color: white;
  text-decoration: none;
}

.imageButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  margin-bottom: 10%;
}

.imageButton button:hover {
  background-color: #ab9e90;
}

.service img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.service p {
  margin: 10px 0;
}

.service button {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

/* About.css */
.about {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 70%;
  margin: auto;
  gap: 30px;
}

.about img {
  width: 40%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.about-text {
  flex: 1;
}

.about h1 {
  font-size: 35px;
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: uppercase;
}

.about h3 {
  color: #a9a9a8;
  font-style: italic;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
}

.about p {
  color: #333333;
  font-weight: 300;
  font-size: 15px;
  font-family: "Roboto";
}

.about button {
  background-color: transparent;
  color: black;
  border: solid;
  padding: 15px 50px;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 600;
}

.about button a {
  color: black;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 600;
}

/* Gallery.css */
.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px 0;
}

.gallery img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* Testimonials.css */
.testimonials {
  text-align: center;
  padding: 20px;
  padding-bottom: 40px;
}
.testimonials h2 {
  margin-bottom: 0;
}

.stars {
  font-size: 15px;
}

.testimonials p {
  margin: 10px auto;
  font-size: 16px;
  font-family: "Roboto";
  color: #c5c5c5;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials p:first-of-type {
  margin-top: 20px;
}

/* Payment Form  */

.paymentForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paymentForm h2 {
  color: white;
  font-weight: 500;
}
.paymentForm button {
  background-color: #ab9e90;
  color: #ffffff;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  margin-top: 20px;
}

.paymentForm button:hover {
  background-color: #000000;
  color: #ffffff;
}

.g-recaptcha {
  margin: 20px;
  margin-left: 0px;
}

.StripeElement {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Oswald", sans-serif;
  font-size: 17px;
  height: 43px;
  margin: 10px 0;
  padding-top: 10px;
}

.ElementsApp input {
  font-family: "Oswald", sans-serif;
}
.payment-popup {
  padding: 3%;
  border-radius: 5px;
  max-width: 400px;
}
.payment-popup p {
  color: #ab9e90;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}
button#payment-request-button {
  left: unset;
  transform: unset;
}
#payment-request-button {
  border: none;
}

/* Footer.css */
footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ab9e90;
  color: white;
  padding: 20px 150px;
  padding-bottom: 40px;
}

.footer-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.footer-item.logo {
  font-size: 24px;
}

.footer-item.instagram {
  margin-top: 10px;
}

.footer-item.instagram i {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.footerAddress {
  display: flex;
  font-weight: 400;
  font-size: 25px;
  text-transform: uppercase;
}

.footer-item.logo img {
  width: 200px;
  margin-top: 20%;
}

.footerAddressDiv {
  font-weight: 300;
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
}

.policiesButton {
  padding: 15px 70px;
  background: transparent;
  border: solid 2px white;
  color: white;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
}

.openingHoursDiv {
  display: flex;
  justify-content: space-between;
  width: 240px;
  font-weight: 300;
  font-family: "Roboto";
}

.burger-menu {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .productContainer {
    width: 80% !important;
  }
}

/* Media Queries for Tablets and Mobiles */
@media only screen and (max-width: 768px) {
  .grey-blending-banner {
    padding: 10px 0;
    top: 120px !important;
  }
  .mobileNavLogo {
    top: 20px;
    left: 15px;
  }

  .mobileNavLogo img {
    width: 100px;
  }

  .mobileNavWhatsapp img {
    width: 65px;
    position: relative;
    display: flex;
  }

  /* Hide the regular navigation links by default */
  .nav-links {
    display: none;
  }

  /* Hide the burger menu icon on full-size screens */
  .burger-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .mobileNavMain {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-top: 4%;
    background-color: #ab9f90;
    gap: 60px;
    padding-right: 5%;
  }

  .burger-menu img {
    width: 40px;
    /* Adjust size as needed */
  }

  .mobile-nav {
    display: flex;
    width: 100%;
    background-color: #ab9f90;
    height: 100vh;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
  }

  .mobile-nav-links a {
    padding: 20px;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
  }

  .close-button {
    left: 48% !important;
    top: 32px !important;
  }

  .close-button span {
    font-size: 5rem;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #282c34;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
    padding: 10px;
    text-align: center;
  }

  footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-item {
    width: 100%;
    text-align: center;
  }

  .mobile-nav .logo img {
    max-width: 170px;
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .openingHoursDiv {
    width: 70vw;
  }

  .mobile-nav-links {
    display: flex;
    align-items: center;
    background-color: #ab9e90;
    font-family: "Roboto";
    text-transform: uppercase;
    color: white;
    font-size: 13px;
    padding: 15px 0px;
    flex-direction: column;
  }

  .main-hero {
    font-size: 63px;
    height: 100vh;
    text-align: center;
  }

  .about {
    flex-direction: column;
    width: unset;
  }

  .about img {
    width: 100%;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .services {
    display: block;
  }

  .service1,
  .service2,
  .service3 {
    width: 100%;
  }

  .formContainer {
    max-width: 320px !important;
    z-index: 1;
    position: relative;
  }

  .menuCategory {
    width: 80% !important;
  }

  .products .mainContainer {
    width: 90% !important;
  }

  .products .productContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .products .individualProduct {
    width: 100%;
  }

  .servicesContainer .individualProduct {
    width: 90%;
  }

  .servicesContainer {
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
  .bookButton {
    right: 20px !important;
    bottom: 15px !important;
  }
  .testimonials p {
    width: 90%;
  }
  .promo-text {
    width: 95% !important;
  }
  .promo-container {
    flex-direction: column;
    padding: 0 !important;
    width: 90% !important;
  }
  .promo-button {
    width: 80%;
  }
  form.login-form {
    width: 75% !important;
  }
  .login-form button {
    width: 100%;
  }
  .modal-header .close-btn {
    top: 0;
    right: 0;
    padding: 10px 20px;
  }
  .payment-popup {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
}

p.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 22px;
}
.formContainer {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #ab9e90;
}

.singleItem {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
  color: #ab9e90;
  /* Beige text color */
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Oswald", sans-serif;
  font-size: 17px;
}

.textArea textarea {
  resize: vertical;
}

.formBtn {
  text-align: center;
  margin: 15px;
}

button {
  background-color: #ab9e90;
  /* Beige color */
  color: white;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.msg {
  display: none;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  color: #ab9e90;
  /* Beige text color */
}

.msgAppear {
  display: block;
}

.SvgCloseMsg {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}

.priceList {
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;
  flex-wrap: wrap;
}

.menuCategory {
  width: 40%;
}

.dmRestaurantMenuCol .menuItemLeft {
  float: left;
  width: 60%;
  padding-right: 40px;
}

.dmRestaurantMenuCol .menuItemPrice {
  width: 30% !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  white-space: nowrap;
}

.dmRestaurantMenuCol .menuCategory {
  background-color: transparent;
  text-align: left;
  font-weight: 700;
  box-shadow: none;
  padding: 20px 0 40px;
  margin-bottom: 10px;
  cursor: pointer;
  clear: both;
}

.menuItemBox {
  display: flex;
  margin-bottom: 5%;
  padding-bottom: 5%;
  border-bottom: solid 1px grey;
  width: 90%;
}

p.rteBlock {
  margin-bottom: 0;
}

.menuItemDesc,
.lizzie-fox-hair p,
.lizzie-fox-hair ul {
  color: rgba(102, 102, 102, 0.7);
  font-family: "Roboto";
}

h3.menuCategroyTitle,
.lizzie-fox-hair h1,
.lizzie-fox-hair h2 {
  font-size: 30px;
  font-family: "Oswald";
  font-weight: normal;
}

.menuItemName {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 15px;
}

.menuItemsWrapper.expanded {
  display: none;
  /* Initially hide the content */
}

.menuItemsWrapper {
  display: block;
  /* Show the content when the expanded class is present */
}

.menuCategory img {
  width: 20px;
  margin: 0px 15px;
  height: 20px;
}

.lizzie-fox-hair {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.products {
  background: white;
  padding: 1% 3%;
  text-align: center;
}

.products .mainContainer {
  width: 50%;
  margin: auto;
}

.products h2,
.products h3 {
  text-transform: uppercase;
  font-family: "Oswald";
  font-weight: normal;
}

.products p {
  font-family: "Roboto";
  color: rgb(68, 68, 68);
}

.products img {
  width: 90%;
  object-fit: cover;
  height: 190px;
}

.productContainer {
  width: 50%;
  display: flex;
  margin: auto;
}

.individualProduct {
  width: 50%;
}

.servicesContainer {
  width: 90%;
  display: flex;
  margin: auto;
}

.servicesContainer img {
  width: 90%;
  object-fit: cover;
  height: 350px;
}

.servicesContainer h3 {
  font-size: 24px;
}

.servicesContainer p {
  font-size: 14px;
}

.bookButton {
  animation: shake-animation 7s ease infinite;
  transform-origin: 50% 50%;
}
@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.bookButton {
  background-color: white;
  color: #554b41;
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 15px;
  width: 85px;
  height: 85px;
  position: fixed;
  right: 40px;
  bottom: 45px;
  border-radius: 50%;
  border: solid 1px black;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 9;
  -webkit-box-shadow: 13px 13px 28px -7px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 13px 13px 28px -7px rgba(0, 0, 0, 0.65);
  box-shadow: 13px 13px 28px -7px rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.bookButton:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 20px rgb(0, 0, 0);
}

.bookButton:active:after {
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}
.bookButton .option {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0; /* Start from the left edge */
  opacity: 0;
  transition: opacity 0.5s ease-in-out, left 0.1s ease-in-out; /* Added transition for left property */
  -webkit-box-shadow: 13px 13px 28px -7px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 13px 13px 28px -7px rgba(0, 0, 0, 0.65);
  box-shadow: 13px 13px 28px -7px rgba(0, 0, 0, 0.65);
}

/* .bookButton {
  animation: bookButton 1s ease infinite;
}
@keyframes bookButton {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
} */

.bookButton.open .option {
  opacity: 1;
  left: 0; /* Slide in from the left */
}

.bookButton .option.email {
  left: -70px;
}

.bookButton .option.whatsapp {
  left: -140px;
}

.bookButton .option.instagram {
  left: -210px;
}
.bookButton .option.greyblending {
  left: -280px;
}

.bookButton .option img {
  width: 30px;
}
.copyright-text {
  width: 100%;
  background: black;
  color: white;
  text-align: center;
  margin: -16px 0;
}
.copyright-text p {
  padding: 1rem;
}
.copyright-text a {
  color: white;
  text-decoration: underline;
}

/* PAY POP UP */

/* Popup Modal Styles */

.open-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: white;
  color: #282c34;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
}

.open-button:hover {
  background-color: #ffffff;
  color: #333;
  transition: 0.3s ease;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.payment-popup {
}

.payment-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #ab9e90;
}

.input-group input,
.card-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 5px;
  color: #ffffff;
}

.input-group input:focus,
.card-input:focus {
  outline: none;
  border-color: white;
}

.pay-button {
  width: 100%;
  padding: 12px;
  background-color: #ffffff;
  color: #282c34;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pay-button:hover {
  background-color: #ffffff;
  color: #333;
  transition: 0.3s ease;
}

.close-button {
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px;
  position: relative;
  left: 93%;
  top: 25px;
}

.close-button:hover {
  color: #ab9e90;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.option-button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ab9e90;
  color: white;
}

.option-button.active {
  background-color: #333;
}

.option-button:disabled {
  display: none;
}

.grey-blending-banner {
  width: 100vw;
  height: 50px;
  background: white;
  position: fixed;
  top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
}

.promo-container {
  display: flex;
  padding: 20px;
  background-color: #ffffff;
  width: 80%;
  align-items: center;
  margin: auto;
}

.promo-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  cursor: pointer;
}

.promo-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.promo-text {
  padding: 20px;
  color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
}

.promo-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.promo-text p {
  font-size: 1rem;
  margin-bottom: 10px;
  max-width: 80%;
}

.promo-text ul {
  list-style-type: disc;
  padding-left: 20px;
}

.promo-text li {
  margin-bottom: 5px;
}

.promo-button {
  background-color: #ab9e90;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 50%;
  font-family: "Oswald", sans-serif;
}

.promo-button:hover {
  background-color: #333;
}
form.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: auto;
  margin-top: 50px;
  gap: 15px;
}

/* Modal Overlay */
.ebook-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

/* Modal Header with Close Button */
.modal-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-btn {
  font-size: 34px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  position: fixed;
  top: 10px;
  right: 0;
  z-index: 999;
}

.close-btn:hover {
  color: #ab9e90;
}

/* SVG Pages */
.ebook-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 3%;
  width: 95%;
}

.ebook-page {
  max-width: 90%;
  max-height: 90vh;
  pointer-events: none; /* Blocks interaction with SVG */
  user-select: none; /* Prevents text selection */
}
.open-ebook-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
}
/* Horizontal Scrolling for Larger Screens */
@media (min-width: 768px) {
  .ebook-pages {
    flex-direction: row; /* Horizontal layout */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    scroll-snap-type: x mandatory; /* Smooth snapping */
    gap: 30px; /* Space between pages */
    max-width: 100%;
    padding: 10px;
    width: 75%;
  }

  .ebook-page {
    flex: 0 0 auto; /* Prevent shrinking/growing */
    width: 45vw; /* Adjust the size for readability */
    height: auto;
    scroll-snap-align: center; /* Snap to the center */
    object-fit: contain;
  }
}
/* Checkout summary container */
.checkout-summary {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  font-family: "Oswald", sans-serif;
}

/* Product details */
.product-details {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.product-image {
  width: 100px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
}

.product-info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.product-info p {
  margin: 4px 0 0;
  color: #6e6e6e;
  font-size: 14px;
  text-align: left;
}

/* Total details */
.total-details {
  margin: 16px 0;
}

.total-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 8px;
}

.total-row strong {
  font-weight: 700;
}

/* Place order button */
.place-order-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e0e0;
  border: none;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 500;
  color: #6e6e6e;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
}

.place-order-btn .lock-icon {
  margin-left: 8px;
  font-size: 14px;
}

/* Button hover effect (if enabled later) */
.place-order-btn:hover {
  background: #d4d4d4;
}

/* Stripe CSS */

.InputContainer .InputElement {
  font-size: 18px;
  font-family: "Oswald", sans-serif !important;
}

.termsPage {
  background-color: #ab9e90;
  color: #ffffff;
  padding: 2rem;
}
.termsPage h1 {
  padding-top: 1rem;
  margin-top: 0;
}
.termsPage a {
  color: black;
}
.disabled-cursor {
  cursor: not-allowed;
}
.hidden {
  display: none;
}
.name-input {
  margin-bottom: 10px;
}
.name-input::placeholder {
  color: #6e6e6e;
}